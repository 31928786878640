import { withTranslation } from 'react-i18next';
import './Services.css';

const Services = ({ t }:any) => {

  const services = [
    { name: t("AirFreight"), text: t("AirFreightText")},
    { name: t("OceanFreight"), text: t("OceanFreightText")},
    { name: t("Warehousing"), text: t("WarehousingText")}
  ]
  return (
    <div id='services' className='services-container'>
      <div className="services-title">
        {t("OurServices")}
      </div>
      <div className='services'>
        <div className='service1'>
          <div className='service-text'>
            <p className='service-title'>
              {services[0].name}
            </p>
            <span className='service-subtitle'>
              {services[0].text}
            </span>
          </div>
        </div>
        <div className='service2'>
          <div className='service-text'>
            <p className='service-title'>
              {services[1].name}
            </p>
            <span className='service-subtitle'>
              {services[1].text}
            </span>
          </div>
        </div>
        <div className='service3'>
          <div className='service-text'>
            <p className='service-title'>
              {services[2].name}
            </p>
            <span className='service-subtitle'>
              {services[2].text}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Services);
