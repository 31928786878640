import { withTranslation, useTranslation } from 'react-i18next';
import './Navbar.css';
import { useState } from "react";
import logo from '../../resources/logo-navbar.png'
import MenuIcon from '@mui/icons-material/Menu';


const Navbar = ({ t }:any) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('es');

  const { i18n } = useTranslation()

  const changeLanguageHandler = () =>
  {
    setCurrentLanguage(currentLanguage === 'en' ? 'es' : 'en')
    i18n.changeLanguage(currentLanguage);
  }

  return (
    <nav className="navigation">
      <a href="/" className="brand-name">
      <img width={'50%'} src={logo} alt='logo'/>
      </a>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <MenuIcon sx={{ marginLeft: '4%' }} />
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <li>
            <a href="#home" onClick={() => setIsNavExpanded(!isNavExpanded)}>{t("Home")}</a>
          </li>
          <li>
            <a href="#values" onClick={() => setIsNavExpanded(!isNavExpanded)}>{t("Values")}</a>
          </li>
          <li>
            <a href="#services" onClick={() => setIsNavExpanded(!isNavExpanded)}>{t("Services")}</a>
          </li>
          <li>
            <a href="#contact" onClick={() => setIsNavExpanded(!isNavExpanded)}>{t("Contact")}</a>
          </li>
        </ul>
      </div>
      <div onClick={changeLanguageHandler} className="language">
          {currentLanguage.toUpperCase()}
        </div>
    </nav>
  );
}

export default withTranslation()(Navbar);
