import { withTranslation } from 'react-i18next';
import './Banner.css';
import bannerForeground from '../../resources/banner-foreground.png'
import bannerForegroundMobile from '../../resources/banner-foreground-mobile.png'

const Banner = ({ t }:any) => {
  return(
    <div className='banner-container'>
      <div className='banner'>
        <div>
          <span className="values-title">{t("BannerText")}</span>
        </div>
        <a href="#contact"><button className="btn">{t("ContactUs")}</button></a>
      </div>
      <picture>
        <source media="(max-width:1024px)" srcSet={`${bannerForegroundMobile}`} />
        <img src={bannerForeground} alt="Banner" />
      </picture>
    </div>
  )
}

export default withTranslation()(Banner);
