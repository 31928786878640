import { withTranslation } from 'react-i18next';
import './Values.css';
import value1En from '../../resources/value1-en.png'
import value1Es from '../../resources/value1-es.png'
import value2En from '../../resources/value2-en.png'
import value2Es from '../../resources/value2-es.png'
import value3En from '../../resources/value3-en.png'
import value3Es from '../../resources/value3-es.png'
import value4En from '../../resources/value4-en.png'
import value4Es from '../../resources/value4-es.png'

const LanguageSelector = (t:any, imgEn:any, imgEs:any):any => {
  if (t("Language") === "ES") {
    return imgEs;
  }
  return imgEn;
} 

const Values = ({ t }:any) => {
  return(
    <div id='values' className="values">
      <div className="text">
        <span className="values-title">{t("ValuesTitle")}</span>
        <span className="values-title">{t("ValuesSubtitle")}</span>
      </div>
      <div className="value-column">
        <div className="value-row">
          <img src={LanguageSelector(t, value1En, value1Es)} alt='value'/>
          <img src={LanguageSelector(t, value2En, value2Es)} alt='value'/>
        </div>
        <div className="value-row">
          <img src={LanguageSelector(t, value3En, value3Es)} alt='value'/>
          <img src={LanguageSelector(t, value4En, value4Es)} alt='value'/>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(Values);
