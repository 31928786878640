import React from 'react';
import './App.css';

import Navbar from '../Navbar';
import Banner from '../Banner';
import Values from '../Values';
import Contact from '../Contact';
import Services from '../Services';
import Footer from '../Footer';


function App() {
  return (
    <div className="App">
      <Navbar />
      <Banner />
      <Values />
      <Services />
      <Contact/>
      <Footer />
    </div>
  );
}

export default App;
