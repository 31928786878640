import { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useForm, ValidationError } from '@formspree/react';
import { Collapse, Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './Contact.css';


const ContactNotification = ({ notify } : { notify: boolean}) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(notify);
  }, [notify]);

  return (
    <Collapse in={open}>
      <Alert
        variant="filled"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        Thank you for contacting us!
      </Alert>
    </Collapse>

  );
};

function ContactForm({ t, state, handleSubmit }: {t: any, state: any, handleSubmit: any}) {
  return (
    <form id="contact" className='contact-form' onSubmit={handleSubmit}>
      <div className='contact-col'>
        {state.succeeded && (<ContactNotification notify={state.succeeded}/>)}
        <div className="field">
          <label className="field-label">{t("Email")}</label>
          <input
            id="email"
            type="email"
            name="email"
            placeholder={t("YourEmail")}
            tabIndex={-3}
            required
            className="contact-input"
          />
        </div>
        <ValidationError
          prefix="Email"
          field="email"
          errors={state.errors}
        />
      </div>
      <div className='contact-col'>
        <div className="field">
          <label className="field-label">{t("Message")}</label>
          <textarea
            id="message"
            name="message"
            placeholder={t("YourMessage")}
            rows={8}
            tabIndex={-4}
            required
            className="contact-input"
          />
        </div>
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
      </div>
      <div className="field-button">
        <button
          className="contact-button"
          name="submit"
          type="submit"
          id="contact-submit"
          disabled={state.submitting}
        >
          {t("Send")}
        </button>
      </div>
    </form>
  );
}


function Contact({ t }:any) {
  const [state, handleSubmit] = useForm('moqbrzvo');
  return (
      <div className="contact-container">
         <div className='contact-col'>
           <div className='block-container'>
              <h2 className='contact-title'>{t("ContactTitle")}</h2>
              <div className='separator' />
              <div className='text-wrapper'>
                <p className='content'>
                  {t("ContactSub1")}
                </p>
                <p className='content'>
                  {t("ContactSub2")}
                </p>
              </div>
           </div>
         </div>
         <div className='contact-col'>
            <ContactForm t={t} state={state} handleSubmit={handleSubmit} />
          </div>
      </div>
  );
}

export default withTranslation()(Contact);
