
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PublicIcon from '@mui/icons-material/Public';

import logo from '../../resources/temp-logo.png'
import './Footer.css';
const Footer = () => {

  return (
    <div id='footer' className='footer' >
      <img src={logo} height='100px' alt="RTS Logo"/>
      <div className='footer-rows'>
        <div className='footer-row'>
          <h4><a href="mailto:info@rtscargo.com">info@rtscargo.com</a></h4>
          <MailOutlineIcon sx={{ marginLeft: '4%' }} />
        </div>
        <div className='footer-row'>
          <h4><a href="https://www.rtscargo.com/">www.rtscargo.com</a></h4>
          <PublicIcon sx={{ marginLeft: '4%' }} />
        </div>
        <div className='footer-row'>
          <h4>
            <a href="https://goo.gl/maps/fnpacCyKyE9PoF2U6" target="_blank" rel="noopener noreferrer">10913 Northwest 30th Street<br />Doral, Florida, United States
            </a>
          </h4>
          <LocationOnIcon sx={{ marginLeft: '4%' }} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
